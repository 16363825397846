.toggle-button {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 24px;
    width: 30px;
    background: transparent;
    border: none;
    padding: 0;
    box-sizing: border-box;
    z-index: 300;
    right: 35px;
    cursor: pointer;
    @include easeOut;

    &:focus {
        outline: none;
    }

    .toggle-button-line {
        width: 30px;
        height: 3px;
        background: linear-gradient(to right, $accent-text, $secondary-text);
        margin: 0 0 5px 0;
        @include easeOut;
    }
}

.sidedrawer.open {
    transform: translateX(0);
}

.sidedrawer {
    height: 100%;
    background-color: $off-white;
    box-shadow: 1px 3px 10px rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    right: 0;
    width: 40%;
    max-width: 240px;
    z-index: 200;
    transform: translateX(100%);
    transition: transform 0.3s ease-out;

    .fas {
        display: flex;
        flex-direction: row;
        justify-content:flex-end;
        padding: 18px 18px 0 0;
        color: $background;
        font-size: 1.4rem;
    }
    
    ul {
        height: 100%;
        list-style: none;
        display: flex;
        flex-direction: column;
        margin-top: 3rem;
        
        li {
            margin: 1rem;
            
            a {
                color: $background;
                text-decoration: none;
                font-size: 1.2rem;
                
                &:hover, &:active {
                    color: $accent-text;
                    position: relative;
                    right: -5px;
                }
            }
        }
    }
}