.about {
  padding: 100px 15px 85px 15px;
  background-color: $background-light;
  
  .about-content {
    display: flex;
    justify-content: center;
    justify-self: center;
    justify-items: center;
    margin: 0 140px;
    
    .text {
      margin-top: 22px;
      text-align: center;
      max-width: 80%;

      h4 {
        font-size: 32px;
        font-weight: 600;
        margin: 0 0 5px;
        color: $dark-text;
      }

      h6 {
        font-size: 18px;
        font-weight: 500;
        margin: 18px 0px;
        color: $dark-text;
      }

      span {
        color: $accent-text;
      }

      p {
        font-family: $font-family;
        font-weight: 400;
        line-height: 24px;
        color: $background;
        margin: 0 0 20px;

      }
      .highlight {
        font-size: 26px;
        margin: 20px 0;
      }
      .tech-skills {
        font-size: 28px;
        margin: 20px 0;
      }

      .social-links {
        padding-top: 55px;

        a {
          height: 30px;
          width: 30px;
          border-radius: 3px;
          display: inline-block;
          text-align: center;
          line-height: 32px;
          margin: 0 10px;
          transition: all .5s ease;

          &:hover {
            background-color: transparent;

            .fab {
              color: $background;
              margin-bottom: 5px;
              position: relative;
              top: -5px;
            }
          }

          .fab {
            color: $background;
            font-size: 28px;
            transition: all .5s ease;
          }
        }
      }

      .info {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .list {
          flex: 0 0 calc(50% - 15px);
          max-width: calc(50% - 15px);
          margin-top: 10px;
          display: flex;
          flex-wrap: wrap;
          padding-bottom: 6px;

          label {
            padding-right: 10px;
            color: $dark-text;
            font-weight: 600;
            font-size: 15px;
          }

          p {
            margin: 0;
            font-size: 15px;
            font-weight: 400;
            line-height: 24px;
            color: $para-text;
          }
        }
      }
    }
  }

  .img {
    display: flex;
    max-width: 100%;
    justify-content: center;
    
    img {
      display: block;
      max-height: 30%;
      max-width: 30%;
      border-radius: 100%;
      border: 8px solid $background;
    }
  }

  .section-title {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 30px;
    text-align: center;

    h1 {
      display: inline-block;
      font-size: 35px;
      text-transform: uppercase;
      font-weight: 700;
      margin: 0 0 10px;
      color: $dark-text;
      position: relative;
      padding-bottom: 0;

      &:before {
        content: '';
        position: absolute;
        left: 30%;
        right: 30%;
        height: 2px;
        background-color: $accent-text;
        bottom: 0;
      }
    }

    p.small {
      display: block;
      font-size: 14px;
      color: $para-text;
      font-weight: 400;
      letter-spacing: 2px;
    }
  }
}