.blogs {
    padding: 100px 15px;
    background-color: $background-light;

    .blogs-content {
        flex: 0 0 100%;
        max-width: 100%;
        display: flex;
        flex-wrap: wrap;

        .box {
            flex: 0 0 calc(33.33% - 20px);
            max-width: calc(33.33% - 20px);
            margin: 10px;

            &:hover img {
                transform: scale(1.05);
            }

            h3 {
                font-size: 20px;
                font-weight: 600;
                color: $dark-text;
                margin: 0 0 10px;
                padding: 0;
            }

            p {
                font-size: 15px;
                font-weight: 400;
                line-height: 24px;
                color: $background;
                font-family: $font-family;
                margin: 0 0 10px;
            }

            a {
                color: $accent-text;
                font-size: 14px;
                text-transform: uppercase;
                font-weight: 600;
                display: inline-block;
                text-decoration: none;
                border-bottom: 1px solid $accent-text;
                transition: all .5s ease;
                -webkit-transition: all .5s ease;

                &:hover {
                    color: $dark-text;
                    border-color: $dark-text;
                }
            }

            .info {
                padding: 10px 0;

                span {
                font-size: 12px;
                color: $para-text;
                display: inline-block;

                    &:nth-child(2) {
                        border-left: 1px solid $para-text;
                        padding-left: 8px;
                        margin-left: 10px;
                    }
                }
            }

            .img {
                overflow: hidden;
                max-height: 50%;
                display: flex;
                flex-direction: column;

                img {
                    width: 300px;
                    height: 200px;
                    // width: 100%;
                    // height: 100%;
                    background-size: cover;
                    // display: block;
                    transition: all .5s ease;
                    justify-content: center;
                }
            }
        }
    }
}