.skills {
  padding: 100px 70px;

  .section-title h1:before {
    left: 30%;
  }

  .img {
    flex: 0 0 50%;
    max-width: 50%;

    img {
      width: 100%;
      display: block;
    }
  }

  .skills-content {
    flex: 0 0 50%;
    max-width: 50%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-right: 40px;

    .skill-box {
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: 30px;

      h6 {
        font-size: 16px;
        font-weight: 500;
        color: $dark-text;
        margin: 0 0 8px;
      }

      .skill-bar {
        height: 5px;
        background-color: $para-text;
        position: relative;

        .skill-bar-in {
          background-color: $accent-text;
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;

          span {
            color: $para-text;
            font-size: 16px;
            font-weight: 400;
            position: absolute;
            right: 0;
            top: -30px;

          }
        }
      }
    }

    .section-title {

      p {
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
        color: $para-text;
        margin: 15px 0 0;
      }
    }
  }
}