@media(max-width: 991px){
    .home .home-content .block h1 {
        font-size: 50px;
    }
}

@media (min-width: 769px) {
    .sidedrawer {
        display: none;
    }

    .toolbar-toggle-button {
        display: none;
    }

    div.brand-name {
        margin-left: 0;
    }
}

@media(max-width: 768px) {

    .home .home-content .block h1 {
        font-size: 40px;
    }

    .home .home-content .block h3 {
        font-size: 25px;
    }

    header {
        padding: 15px;
        position: fixed;
        background-color: #fff;
        border: none;

        div.navbar {
            display: none;
        }

        .brand-name a {
            color: #000;
        }
    }

    .about .about-content {
        margin: 0 20px;
    }

    .about .text {
        flex: 0 0 100%;
        max-width: 100%;
        padding-left: 0px;
    }

    .about img.img {
        flex: 0 0 80%;
        max-width: 80%;
    }

    .resume .resume-content {
        flex: 0 0 100%;
        max-width: 100%;
        padding-right: 0;
    }

    .resume .img {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .portfolio .portfolio-gallery .item {
        flex: 0 0 50%;
        max-width: 50%;
        position: relative;
        padding: 8px;
    }

    .blogs .blogs-content .box {
        flex: 0 0 100%;
        max-width: 80%;
        margin: 24px;

        .img {
            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    .contact .contact-form {
        padding: 40px 20px;

        .col-6 {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    .about-me .about-content .text .info .list p {
        margin: 0;
        flex: 0 0 100%;
    }
}

@media(max-width: 500px){

    h1::before {
        display: none;
    }

    .home .home-content .block h1 {
        font-size: 34px;
    }

    .home .home-content .block h3 {
        font-size: 20px;
    }

    .about .about-content .text h6 {
        margin-bottom: 14px;
    }

    .home .home-content .block {
        padding-left: 20px;
        margin-top: 240px;
    }

    .portfolio .portfolio-gallery .item {
        flex: 0 0 100%;
        max-width: 100%;
        position: relative;
        padding: 8px;
    }

    .resume {
        padding: 80px 30px; 

        .opportunity-row {
            margin-bottom: 1.2rem;
            margin-top: 1.2rem;
        }
    }

    .about img.img {
        flex: 0 0 80%;
        max-width: 80%;
        max-height: 275px;
    }

    footer {
        .row {
            flex-direction: column;
        }

        .social {
            text-align: center;
            margin-top: -12px;
        }

        .logo {
            text-align: center;
            margin-top: 8px;
            margin-bottom: -12px;
        }
    }
}