@import 'config';
@import 'about';
@import 'skills';
@import 'services';
@import 'portfolio';
@import 'blogs';
@import 'contact';
@import 'resume';
@import 'header';
@import 'sidedrawer';
@import 'backdrop';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  height: 100%;
  font-family: $main-font;
  margin: 0;
}

.container {
  max-width: 1140px;
  margin: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

header {
  position: fixed;
  left: 0;
  right: 0;
  padding: 10px 15px;
  z-index: 11;
  background-color: $light-text;
  color: $color-text;

  .row {
    justify-content: space-between;
    align-items: center;
  }

  .brand-name {
    font-size: 22px;
    font-weight: 600;
    color: $background;
    padding: 6px;
    left: 0;
    bottom: 0;

    &:hover {
      transform: rotateY(360deg);
      transition: all 2s ease;
    }

    a {
      transform: rotateY(-45deg);
      font-size: 22px;
      font-weight: 600;
      color: $dark-text;
      text-transform: uppercase;
      position: relative;
      text-decoration: none;
    }
  }

  .navbar ul {
    list-style: none;
    padding: 0;
    margin: 0;
  
    li {
      display: inline-block;
      margin-left: 20px;
  
      a {
        font-size: 15px;
        text-decoration: none;
        line-height: 72px;
        color: #fff;
        // color: $accent-text;
        text-transform: uppercase;
        font-weight: 600;
        position: relative;
        display: block;
  
        &::before {
          content: '';
          height: 2px;
          background-color: $accent-text;
          width: 0%;
          position: absolute;
          right: 0;
          bottom: 22px;
          transition: all .5s ease;
        }
  
        &:hover::before {
          width: 100%;
          left: 0;
        }
        
        &.active::before {
          color: $accent-text;
          width: 100%;
          left: 0;
        }
      }
    }
  }
}

.section-title {
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 40px;

  h1 {
    display: inline-block;
    font-size: 35px;
    text-transform: uppercase;
    font-weight: 700;
    margin: 0 0 10px;
    color: $dark-text;
    position: relative;
    padding-bottom: 0;

    &:before {
      content: '';
      position: absolute;
      left: 30%;
      right: 30%;
      height: 2px;
      background-color: $accent-text;
      bottom: 0;
    }
  }
}

.home {
  min-height: 85vh;
  background-color: $background;
  background-size: cover;
  background-position: center;
  padding: 15px;

  .full-screen {
    background-color: #999;
    min-height: 100vh;
  }

  .home-content {
    flex: 0 0 100%;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .block {
      flex: 0 0 75%;
      max-width: 75%;
      padding-left: 45px;
      margin-top: 300px;

      .home-description {
        font-family: $font-family;
        color: $accent-text;
      }

      h6 {
        color: $light-text;
        font-size: 18px;
        font-weight: 500;
        margin: 0;
        padding: 0;
      }

      h1 {
        color: $background-light;
        font-size: 70px;
        font-weight: 600;
        margin: 0;
        padding: 0;

      }

      h3 {
        font-size: 18px;
        font-weight: 500;
        margin: 0;
        padding: 0;
        color: rgb(177, 175, 175);
      }      
    }
  }
}

.cv-btn a {
  font-family: $font-family;
  border-radius: 6px;
  padding-top: 20px;
  margin-top: 20px;
  color: $light-text;
  border: 3px solid $background-light;
  padding: 8px 18px;
  display: inline-block;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  transition: all .8s ease;
  background-color: transparent;
  text-decoration: none;

  &:hover {
    background-color: $background-light;
    color: $dark-text;
  }
}


/* footer */

footer {
  padding: 32px 15px;
  background-color: $background;

  .row {
    display: flex;
    justify-content: space-between;
  }

  .logo {
    // flex: 0 0 100%;
    // max-width: 100%;
    // text-align: center;
    font-size: 32px;
    color: $para-text;
    font-weight: 600;
  }

  .social {
    // flex: 0 0 100%;
    max-width: 100%;
    // text-align: center;
    padding-top: 10px;

    a {
      color: $para-text;
      text-decoration: none;
      display: inline-block;
      margin: 0 12px;
      font-size: 24px;
      transition: all .5s ease;

      &:hover {
        color: $off-white;
      }
    }
  }
}

.copyright {
  background-color: $background;
  padding: 12px;
  border-top: 1px solid rgb(74, 75, 69);

  p {
    color: $para-text;
    margin: 0;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
  }
}


@import 'mobile';