.contact {
    padding: 100px 18px;
    background-color: $background-light;

    .contact-form {
        background-color: $background-light;
        flex: 0 0 100%;
        max-width: 100%;
        padding: 50px;
        border-radius: 10px;
        border: 8px solid $background;
        box-shadow: 0px 1px 3px $background-dark-light;
        
        .text {
            flex: 0 0 100%;
            max-width: 100%;
            padding-bottom: 20px;

            h2 {
                font-size: 26px;
                font-weight: 600;
                color: $dark-text;
                margin: 0;
                padding: 0;
            }

            p {
                font-size: 15px;
                font-family: $font-family;
                font-weight: 400;
                line-height: 24px;
                color: $background;
                margin: 0;
            }
        }

        .col-6 {
            flex: 0 0 calc(50% - 15px);
            max-width: calc(50% - 15px);
        }

        .row {
            justify-content: space-between;
        }

        .col-12 {
            flex: 0 0 100%;
            max-width: 100%;
        }

        .form-control {
            width: 100%;
            margin-bottom: 15px;
            border: 1px solid #eee;
            padding: 6px 12px;
            font-size: 14px;
            font-family: $font-family;

            &::placeholder {
                color: $para-text;
            }
        }

        .button {
            flex: 0 0 100%;
            max-width: 100%;
            text-align: right;
    
            button {
                border-radius: 4px;
                padding-top: 20px;
                color: $light-text;
                border: none;
                padding: 12px 25px;
                font-size: 14px;
                text-transform: uppercase;
                font-weight: 600;
                transition: all .8s ease;
                background-color: $accent-text;
                text-decoration: none;
                box-shadow: 1px 2px 4px $para-text;
    
                &:hover {
                    cursor: pointer;
                    color: $light-text;
                    box-shadow: none;
                }
            }
        }
    }
}

/* alerts */

.alert {
    padding: 0.6rem;
    margin: 1.2rem 0;
    opacity: 0.9;
    color: #333;
    text-align: center;
    margin-bottom: -20px;
    border-radius: 5px;
  }

.error-message {
    border: 3px solid $warning;
    color: $warning;
}

.success-message {
    color: $accent-text;
    border: 3px double $accent-text;
}