@import url('https://fonts.googleapis.com/css?family=Raleway&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Homemade+Apple&family=Monoton&family=Satisfy&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rock+Salt&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bellota&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gideon+Roman&display=swap');

$main-font: 'Gideon Roman', serif;
$font-family: 'Montserrat', sans-serif;

$dark-text: rgb(35, 36, 36);
$color-text: #402a4e;
$accent-text: rgb(204, 143, 163);
$secondary-text: rgb(143, 194, 204);
$para-text: #838383;
$light-grey: #727171;
$background: rgb(64, 65, 58);
$grey: rgb(197, 198, 195);
$background-light: rgb(245, 245, 242);
$background-dark-light: rgb(242, 242, 235);
$warning: #c04b4bd6;
$success: #81cebe;

$off-white: rgb(252, 250, 249);

$light-text: #fff;
// $dark-text: rgb(35, 36, 36);
// $color-text: #402a4e;
// // $accent-text: rgb(123, 64, 96);
// $accent-text: rgb(72, 131, 127);
// $para-text: #838383;
// $light-grey: #d5d5d5;
// $background: rgb(64, 65, 58);
// $grey: rgb(197, 198, 195);
// // $background-light: rgb(240, 240, 236);
// $background-light: rgb(245, 245, 242);
// $background-dark-light: rgb(242, 242, 235);
// $warning: #c04b4bd6;
// $success: #81cebe;

// $off-white: rgb(252, 250, 249);

@mixin easeOut {
  transition: all 0.5s ease-out;
}

/* utilities */

.text-center {
  text-align: center!important;
}

.text-left {
  text-align: left!important;
}
  
.text-right {
  text-align: right!important;
}


.text-uppercase {
  text-transform: uppercase!important;
}

.align-items-center {
  align-items: center;
}