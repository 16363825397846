.toolbar {
    background-color: $background;;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    height: 66px;

    .toolbar-nav {
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 0.4rem;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;

            li {
                color: #fff;
                padding: 0 0.5rem;
                
                &:hover, &:active {
                    color: black;
                }
            }
        }
    }

    .brand-name-container {
        margin-left: -22px;
    }

    .brand-name {
        font-size: 22px;
        font-weight: 600;
        color: $accent-text;
        left: 0;
        bottom: 0;
    
        &:hover {
          transform: rotateY(360deg);
          transition: all 2s ease;
        }
        
        .logo {
          max-height: 70px;
          margin-top: 10px;
        }
    
        a {
          transform: rotateY(-45deg);
          font-size: 22px;
          font-weight: 600;
          color: #fff;
          text-transform: uppercase;
          position: relative;
          text-decoration: none;
        //   transform-origin: center center;
        }
    }
}

.spacer {
    flex: 1;
}