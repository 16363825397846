.portfolio {
    padding: 100px 10px;
    height: 100%;
    background-color: $background-light;

    .section-title {
        left: 30%;

        p {
          font-size: 15px;
          font-weight: 400;
          line-height: 24px;
          color: $background;
          margin: 15px 0 0;
        }
    }

    .filter-buttons {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 25px;

        ul {
            list-style: none;
            text-align: center;
            padding: 0;

            li {
                display: inline-block;
                margin: 0 8px;
                color: $dark-text;
                font-weight: 500;
                font-size: 16px;
                text-transform: uppercase;
                cursor: pointer;
                padding-bottom: 0;

                &.active {
                    color: $accent-text;
                    border-bottom: 2px solid $accent-text;
                }
            }
        }
    }

    .portfolio-gallery {
        flex: 0 0 100%;
        max-width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .item {
            flex: 0 0 33.33%;
            max-width: 33.33%;
            position: relative;
            padding: 10px;

            p {
                font-size: 15px;
                font-family: $font-family;
                font-weight: 400;
                line-height: 24px;
                color: $background;
                margin: 18px 10px;
            }

            .inner {
                position: relative;
            }

            &:hover .overlay {
                opacity: 1;

                a {
                    -webkit-animation: galleryLink .8s ease;
                    animation: galleryLink .8s ease;
                }

                h4 {
                    -webkit-animation: galleryText .8s ease;
                    animation: galleryText .8s ease;
                }

                h5 {
                    -webkit-animation: galleryText2 1s ease;
                    animation: galleryText2 1s ease;
                }
            }

            img {
                width: 100%;
                display: block;
            }

            .overlay {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                display: flex;
                background-color: rgba(0, 0, 0, 0.6);
                z-index: 10;
                flex-wrap: nowrap;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                opacity: 0;
                transition: all .5s ease;
                
                h4 {
                    font-size: 25px;
                    color: #fff;
                    font-weight: 600;
                    text-transform: uppercase;
                    // margin-top: 15px; 
                }

                h5 {
                    font-size: 14px;
                    color: #fff;
                    font-weight: 400;
                    font-style: italic;
                    // margin-top: 10px; 
                }
                
                a {
                    font-size: 14px;
                    color: $off-white;
                    text-transform: uppercase;
                    display: inline-block;
                    text-align: center;
                    line-height: 30px;
                    cursor: pointer;
                    text-decoration: none;
                    border: 2px solid $off-white;
                    border-radius: 4px;
                    padding: 0 10px;
                    margin-top: 10px;

                    &:hover {
                        background-color: $off-white;
                        color: $background;
                        transition: all .5s ease;
                    }
                }
            }
        }
    }
}

@keyframes galleryText {
    0% {
        transform: translateY(-20px);opacity: 0;
    }
    100% {
        transform: translateY(0);opacity: 1;
    }
}

@keyframes galleryText2 {
    0% {
        transform: translateY(-20px);opacity: 0;
    }
    100% {
        transform: translateY(0);opacity: 1;
    }
}

@keyframes galleryLink {
    0% {
        transform: translateY(20px);opacity: 0;
    }
    100% {
        transform: translateY(0);opacity: 1;
    }
}

.lightbox {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        max-width: 100%;
        max-height: 80%;
        display: block;
    }

    .close-lightbox {
        height: 35px;
        width: 35px;
        background-color: $accent-text;
        color: #fff;
        line-height: 35px;
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
        border-radius: 50%;
        z-index: 101;
    }
}