.services {
    padding: 100px 15px;
  
    .section-title h1:before {
      left: 30%;
    }
  
    .service-content {
      flex: 0 0 100%;
      max-width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  
    .box {
      background-color: #fff;
      padding: 30px;
      flex: 0 0 calc(33.33% - 20px);
      max-width: calc(33.33% - 20px);
      margin-bottom: 30px;
      border: 1px solid #eee;
      text-align: center;
      position: relative;
      border-radius: 5px;
  
      &::before {
        content: '';
        height: 2px;
        background-color: $accent-text;
        position: absolute;
        bottom: 0;
        width: 50px;
        left: 0;
        right: 0;
        margin: auto;
        transition: all .5s ease;
      }
  
      &:hover::before {
        width: 100%;
      }
  
      p {
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
        color: $para-text;
        margin: 10px 0 10px;
      }
  
      h5 {
        font-size: 16px;
        font-weight: 600;
        color: $dark-text;
        margin: 0;
  
      }
  
      .icon {
        height: 60px;
        width: 60px;
        background-color: $accent-text;
        border-radius: 50%;
        margin: 15px auto 20px;
        text-align: center;
        
        .fa {
          font-size: 24px;
          color: $light-text;
          line-height: 60px;
        }
      }
    }
  }